
.presentation-item {
  text-decoration: none;
  color: #000;

  .speaker-line {
    font-size: 0.8rem;
    color: grey !important;

    span:not(:last-child)::after {
      content: ", ";
    }

    span {
      font-size: 0.8rem;
      color: grey;
    }
  }

  .card-title {
    font-size: 0.8rem !important;
  }

  .card-text {
    color: grey;
  }

  .timeslot {
    display: flex;
    align-items: center;
    color: grey;
    white-space: nowrap;

    .time-icon {
      font-size: 0.9rem;
    }

    .time {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  .presentation-item-divider {
    width: 98%;
    color: lightgray
  }

  @media (min-width: 768px) {
    .pdf-modal {
      --width: 80%;
      --height: 80%;
    }
  }


}
